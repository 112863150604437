'use strict';


/* ------------------------------------------------------------
	設定
 * ------------------------------------------------------------ */
var common = {};
common.event = {};
var breakPoints = {
	p: 750
}
common.breakPoints = breakPoints;
var locationHash = window.location.hash,
	$windowHeight,
	sc;


/* ------------------------------------------------------------
	userAgent判別
 * ------------------------------------------------------------ */
var ua                   = window.navigator.userAgent;
var appVer               = window.navigator.appVersion;

//PC
var isIE                 = ua.indexOf('MSIE') != -1 || ua.indexOf('Trident') != -1;
var isIE6                = isIE && appVer.indexOf('MSIE 6') != -1;
var isIE7                = isIE && appVer.indexOf('MSIE 7.') != -1;
var isIE8                = isIE && ua.indexOf('Trident/4.') != -1  || appVer.indexOf('MSIE 8.') != -1;
var isIE9                = isIE && ua.indexOf('Trident/5.') != -1;
var isIE10               = isIE && ua.indexOf('Trident/6.') != -1;
var isIE11               = ua.indexOf('Trident/7.') != -1;
var isMicrosoftEdge      = ua.indexOf('Windows NT') != -1;
var isOldIE              = isIE8 || isIE7 || isIE6;

var isFirefox            = ua.indexOf('Firefox') != -1;
var isChrome             = ua.indexOf('Chrome') != -1;
var isSafari             = ua.indexOf('Safari') != -1;

//Mobile (smartphone + tablet)
var isMobileSafari       = ua.match(/iPhone|iPad|iPod/i) ? true : false;
var isMobileSafariTypeT  = ua.match(/ipad/i) ? true : false;
var isMobileSafariTypeS  = ua.match(/iphone|ipod/i) ? true : false;
var isAndroid            = ua.indexOf('Android') != -1;
var isMobileAndroidTypeT = isAndroid && ua.indexOf('Mobile') == -1;
var isMobileAndroidTypeS = isAndroid && ua.indexOf('Mobile') != -1;
var isAndroidChrome      = isChrome && isAndroid;
var isAndroidFirefox     = isFirefox && isAndroid;
var isMobileFirefox      = isFirefox && ua.indexOf('Mobile') != -1;
var isTabletFirefox      = isFirefox && ua.indexOf('Tablet') != -1;

//PC or Mobile
var isTablet             = isMobileSafariTypeT || isMobileAndroidTypeT || isTabletFirefox;
var isSmartPhone         = isMobileSafariTypeS || isMobileAndroidTypeS || isMobileFirefox;
var isMobile             = isTablet || isSmartPhone || isAndroidChrome;
var isPC                 = !isMobile;


// ----------------------------------------
// タブレットのViewport
// ----------------------------------------
var pcViewWidth = 1180 + 60; //60は余白分
var vp = document.querySelector('[name="viewport"]');
if (vp) {
	var media = matchMedia('(min-width: ' + (breakPoints.p - 1) + 'px)');
	if (media.matches) {
		vp.content = 'width=' + pcViewWidth;
	}
}
if (isTablet) {
	$('html').addClass('tablet');
}


/* ------------------------------------------------------------
	Window Resized
* ------------------------------------------------------------ */
// ----------------------------------------
// ブラウザサイズにより切り替え
// ----------------------------------------
function checkViewWidth (){
	if(!common.viewWidth) common.viewWidth = {};
	var vw;
	if( window.matchMedia('(max-width: '+breakPoints.p+'px)').matches ){
		vw = 'sp';
	}
	if( window.matchMedia('(min-width: '+(breakPoints.p+1)+'px)').matches ){
		vw = 'pc';
	}
	common.viewWidth = vw;
	var $html = $('html');
	if(common.viewWidth == 'pc'){
		//PCだったら
		$html.removeClass('sp').addClass('pc');
	} else {
		//スマホだったら
		$html.removeClass('pc').addClass('sp');
	}
}

$(window).on('resize',checkViewWidth);
checkViewWidth();



/* ------------------------------------------------------------
	ローディング
* ------------------------------------------------------------ */
var isLoading = false;
$(function() {
	setTimeout(function(){
		$('html').removeClass('is-loading');
	},300)
	setTimeout(function(){
		$('html').addClass('is-tratition-start');
	},500)
});
$(window).on('load', function() {
	if (isLoading==false) {
		loadingInit();
	}
});
function loadingInit() {
	function loading() {
		$('html').addClass('is-loaded');
	}
	loading();
}


(function() {
	
	
	/* ------------------------------------------------------------
		変数
	* ------------------------------------------------------------ */
	var $headerHeight;
	
	
	/* ------------------------------------------------------------
		Window Resized
	* ------------------------------------------------------------ */
	function resizeInit() {
		$windowHeight = $(window).height();
		$headerHeight = $('.c-header').innerHeight();
	}
	$(window).on('load resize', resizeInit);
	
	
	/* ------------------------------------------------------------
		画像など含めてページ読込み完了後に実行
	* ------------------------------------------------------------ */
	// ----------------------------------------
	// 要素の高さ揃え
	// ----------------------------------------
	function heightAlign() {
		$('.c-button-lv1__link').matchHeight();
		$('.c-button-lv2__link').matchHeight();
		$('.js-matchHeight').matchHeight();
	}
	$(window).on('load', function() {
		heightAlign();
	});


	// ----------------------------------------
	// 要素の高さ揃え（全て）
	// ----------------------------------------
	function heightAllAlign() {
		$('.js-matchHeight-all').matchHeight({
			byRow: false,
		});
	}
	$(window).on('load', function() {
		heightAllAlign();
	});
	
	
	// ----------------------------------------
	// スムーススクロール
	// ----------------------------------------
	function smoothScroll() {
		$('a[href*=\\#]:not([href=\\#])').not(".js-no-scroll, .js-modal__link, a[href*='?id=tab']").click(function() {
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					if(common.viewWidth == 'pc'){
						//PCだったら
						$('html,body').animate({
							scrollTop: target.offset().top
						}, 500);
						return false;
					} else {
						//スマホだったら
						$('html,body').animate({
							scrollTop: target.offset().top - $headerHeight
						}, 500);
						return false;
					}
				}
			}
		});
		if (locationHash != '') {
			if(common.viewWidth == 'pc'){
				//PCだったら
				setTimeout(function(){
					$('html,body').animate({
						scrollTop: $(locationHash).offset().top
					}, 200);
				},200)
			} else {
				//スマホだったら
				setTimeout(function(){
					$('html,body').animate({
						scrollTop: $(locationHash).offset().top - $headerHeight
					}, 200);
				},200)
			}
		}
	}
	$(window).on('load',function(){
		smoothScroll();
	});


	// ----------------------------------------
	// タブリンク
	// ----------------------------------------
	function tablink() {
		$("a[href*='?id=tab']").on('click', function () {
			return false;
		});
		$("a[href*='?id=tab']").on('click', function () {
			var url = location.href;
			url = url.split(window.location.host)[1];
			//console.log(url);
			var str_url = $(this).attr("href");
			var str = str_url.split("#")[1];
			if(str!=""){
				if(url==str_url) {
					location.reload();
				} else {
					location.href=str_url;
				}
			}
		});
		//URLを取得
		var url = location.href;
		//console.log(url.split("#")[1]);
		url = url.split("#")[0];
		
		if ( url.indexOf('?id=tab') != -1) {
			var params = url.split("?");
			var tab = params[1].split("=");
			if($(tab).length){
				var tabname = tab[1];
			}
			$("#"+tabname).click();
			smoothScroll();
		}

		if($(tab).length){
			var tabno = $('.js-tab__trigger#' + tabname).index();
			//クリックされたタブと同じ順番のコンテンツを表示
			$('.js-tab__content').eq(tabno).attr("data-tab", true);
			$('.js-tab__trigger').eq(tabno).attr("data-tab", true);
		}
	}
	$(window).on('load',function(){
		tablink();
	});
	

	/* ------------------------------------------------------------
		DOM構築後実行
	* ------------------------------------------------------------ */
	$(function() {
		
		
		/* 実行
		-------------------------------------------------- */
		$(function() {
			$('html').addClass(uaBrowser());
			heightAlign();
			heightAllAlign();
			header();
			gnav();
			footer();
			submenu();
			accordion();
			tab();
			lazyload();
			telLink();
			table();
			modal();
			var elements = $('.js-sticky');
			Stickyfill.add(elements);
		});
		
		
		// ----------------------------------------
		// ブラウザのバージョン判定
		// ----------------------------------------
		function uaBrowser() {
			var userAgent = window.navigator.userAgent.toLowerCase();
			var appVersion = window.navigator.appVersion.toLowerCase();
			if (userAgent.indexOf('msie') > -1) {
				if (appVersion.indexOf('msie 7.0') > -1) {
					return 'ie ieold';
				}else if (appVersion.indexOf('msie 8.0') > -1) {
					return 'ie ieold';
				}else if (appVersion.indexOf('msie 9.0') > -1) {
					return 'ie ie9';
				}else if (appVersion.indexOf('msie 10.0') > -1) {
					return 'ie ie10';
				}else {
					return 'ie ieold';
				}
			}
			else if (appVersion.indexOf('trident/7.0') > -1) {
				return 'ie ie11';
			}
			else if (userAgent.indexOf('edge') > -1) {
				return 'edge';
			}
			else if (userAgent.indexOf('firefox') > -1) {
				return 'firefox';
			}else if (userAgent.indexOf('opera') > -1) {
				return 'opera';
			}else if (userAgent.indexOf('chrome') > -1) {
				return 'chrome';
			}else if (userAgent.indexOf('safari') > -1) {
				return 'safari';
			}
		}

		// ----------------------------------------
		// ヘッダー
		// ----------------------------------------
		function header() {
			var $menu = $('.c-header__menu'),
				$button =  $('.c-header__hamburger'),
				$menu_close = $('.c-header__menu-overlay, .c-header__menu a[href], .c-header__menu-close-button-link');
			$('.l-header__search-button').on('click', function() {
				$(this).parent('.l-header__search').addClass('is-active');
			});
			$button.on('click', function() {
				var expanded = $(this).attr('aria-expanded');
				if (expanded == 'true') {
					$button.attr('aria-expanded', false);
					$menu.attr('aria-hidden', true);
					$menu.css({
						'padding-bottom': ''
					});
					$('html').attr('data-header-menu', 'false');
					$('body').attr({
						style: ''
					});
					$('html, body').prop({
						scrollTop: sc
					})
					$('.c-footer__page-top').show();
				} else {
					scrollBarhidden();
					$button.attr('aria-expanded', true);
					$menu.attr('aria-hidden', false);
					sc = $(window).scrollTop();
					$('body').css({
						width: '100%',
						position: 'fixed',
						top: -sc,
						overflow: 'hidden'
					});
					$menu.css({
						top: $headerHeight,
						'padding-bottom': $headerHeight,
					});
					$('html').attr('data-header-menu', 'true');
					$('.c-footer__page-top').hide();
					$.fn.matchHeight._update(); // 高さ揃えの更新
				}
			});
			$menu_close.on('click', function() {
				$button.attr('aria-expanded', false);
				$menu.attr('aria-hidden', true);
				$menu.css({
					'padding-bottom': ''
				});
				$('html').attr('data-header-menu', 'false');
				$('body').attr({
					style: ''
				});
				$('html, body').prop({
					scrollTop: sc
				})
				$('.c-footer__page-top').show();
			});

			var initSet = function() {
				var expanded = $button.attr('aria-expanded');
				if (expanded == 'true') {
					if(common.viewWidth == 'pc'){
						$('body').attr({
							style: ''
						});
						$('.c-header__menu-overlay').attr({
							style: ''
						});
					} else {
						//スマホだったら
						scrollBarhidden();
						$menu.css({
							top: $headerHeight,
							'padding-bottom': $headerHeight,
						});
						$('body').css({
							width: '100%',
							position: 'fixed',
							top: -sc,
							overflow: 'hidden'
						});
						$('html').attr('data-header-menu', 'true');
					}
				} else {
					if(common.viewWidth == 'pc'){
						$menu.css({
							'top': '',
							'padding-bottom': ''
						});
						$('html').attr('data-header-menu', 'false');
						$('body').attr({
							style: ''
						});
						$('.c-header__menu-overlay').attr({
							style: ''
						});
					} else {
					}
				}
			}
			initSet();
			$(window).on('resize', initSet);
		}


		// ----------------------------------------
		// グローバルナビゲーション
		// ----------------------------------------
		function gnav(){
			var pageURL = location.pathname,
			pageURLArr = pageURL.split('/'), //パスを分割して配列化する
			pageURLArrCategory = pageURLArr[1]; //パスから第1階層を取得
			$('.c-gnav__item-link').each(function (i, v) {
				var selfhref = $(v).attr('href'),
				hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
				hrefArrCategory = hrefArr[1]; //href属性の第1階層を取得
				//パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
				if (pageURLArrCategory === hrefArrCategory) {
					$(v).attr('data-link-current', 'true');
				}
			});
		}
		
		
		// ----------------------------------------
		// フッター
		// ----------------------------------------
		function footer(){
			$(window).on("scroll", function() {
				if ($(this).scrollTop() > 100) {
					$('.c-footer__page-top').addClass('is-scroll-down');
				} else {
					$('.c-footer__page-top').removeClass('is-scroll-down');
				}
				var scrollHeight = $(document).height();
				var scrollPosition = $(window).height() + $(window).scrollTop();
				var footerHeight = $('.c-footer').innerHeight();
				if ( scrollHeight - scrollPosition  <= footerHeight ) {
					$('.c-footer__page-top').css({
						'position':'absolute'
					});
					$('.c-footer__page-top').addClass('is-scroll-finish');
					$('.c-footer__page-top').removeClass('is-scroll-down');
				} else {
					//それ以外の場合は
					$('.c-footer__page-top').css({
						'position':'fixed'
					});
					$('.c-footer__page-top').removeClass('is-scroll-finish');
				}
			});
			$('.c-footer__page-top').on('click', function () {
				$('body,html').animate({scrollTop:0}, 800, 'swing');
				return false;
			})
		}


		// ----------------------------------------
		// サブメニュー
		// ----------------------------------------
		function submenu(){
			$('.c-submenu__item-link').filter(function(){return $(this).prop('href')==location.href}).attr('data-link-current', 'true');
		}
		
		
		// ----------------------------------------
		// アコーディオン
		// ----------------------------------------
		function accordion() {
			$('.js-accordion__trigger').not('.js-accordion__one .js-accordion__trigger').on('click', function () {
				var $isClosed = $(this).toggleClass('is-active').hasClass('is-active');
				if ($isClosed) {
					$(this).next('.js-accordion__content').show().each(function () {
					}).hide().slideDown(400);
				} else {
					$(this).next('.js-accordion__content').slideUp(400);
				}
				$.fn.matchHeight._update(); // 高さ揃えの更新
				$('img[data-src]').removeAttr("height"); //画像の遅延読み込み更新
				$('img[data-src]').attr({ width: "100%" }); //画像の遅延読み込みの更新
			});

			//アコーディオン ひとつだけ
			$('.js-accordion__one').each(function() {
				var accordion = $(this);
				$(this).find('.js-accordion__trigger').on('click', function () {
					var targetContentWrap = $(this).next('.js-accordion__content');
					if ( targetContentWrap.css('display') === 'none' ) {
						accordion.find('.js-accordion__content').slideUp(400);
						accordion.find('.js-accordion__trigger.is-active').removeClass('is-active');
					}
					targetContentWrap.slideToggle();
					$(this).toggleClass('is-active');
				});
			});

			//アコーディオン 前後に要素がないとき
			$('.js-accordion__leave').each(function() {
				var accordion = $(this);
				$(this).find('.js-accordion__trigger').on('click', function () {
					var targetContentWrap = $(this).parents('.js-accordion__leave').find('.js-accordion__content');
					if ( targetContentWrap.css('display') === 'none' ) {
						accordion.find('.js-accordion__content').slideUp(400);
						accordion.find('.js-accordion__content.is-active').removeClass('is-active');
					}
					targetContentWrap.slideToggle();
					$(this).parents('.js-accordion__leave').find('.js-accordion__content').toggleClass('is-active');
				});
			});

			//アコーディオン 開いた状態
			$('.js-accordion__open.js-accordion__trigger').addClass('is-active');
			$('.js-accordion__open.js-accordion__content').addClass('is-active');
			$('.js-accordion__open.js-accordion__content').slideDown(400);
			
		}
		
		
		// ----------------------------------------
		// タブ
		// ----------------------------------------
		function tab() {
			$(document).on('click', '.js-tab__trigger', function() {
				var $this = $(this),
					$tab = $this.parents('.js-tabs'),
					$tab_trigger = $tab.find('.js-tab__trigger'),
					$tab_content = $tab.find('.js-tab__content'),
					$tab_subtrigger = $tab.find('.js-tab__sub-trigger'),
					$tab_subcontent = $tab.find('.js-tab__sub-content');
				$tab_trigger.attr("data-tab", false);
				$this.attr("data-tab", true);
				var $thisIndex =  $tab_trigger.index(this);
				$tab_content.attr("data-tab", false);
				$tab_content.eq($thisIndex).attr("data-tab", true);
				$.fn.matchHeight._update(); // 高さ揃えの更新
			});
			//タブ切り替えの中でタブ切り替え
			$(document).on('click', '.js-tab__sub-trigger', function() {
				var $this = $(this),
					$tab = $this.parents('.js-tab__content'),
					$tab_trigger = $tab.find('.js-tab__sub-trigger'),
					$tab_content = $tab.find('.js-tab__sub-content');
				$tab_trigger.attr("data-tab", false);
				$this.attr("data-tab", true);
				var $thisIndex =  $tab_trigger.index(this);
				$tab_content.attr("data-tab", false);
				$tab_content.eq($thisIndex).attr("data-tab", true);
			});
		}

		
		// ----------------------------------------
		// 画像の遅延読み込み
		// ----------------------------------------
		function lazyload() {
			function loadImageHandler() {
				$('img[data-src]').each(function() {
					var parentwidth = $(this).parent().width();
					var width = $(this).attr('data-width');
					var height = $(this).attr('data-height');
					height = (parentwidth/width*height);
					width = parentwidth;
					$(this).attr({width: width});
					$(this).attr({height: height});
					$(this).on("load", function() {
						$(this).removeAttr("height");
						$(this).attr({ width: "100%" });
						//console.log("画像 %s をロードしました。");
					});
				});
				$.fn.matchHeight._update(); // 高さ揃えの更新
			}
			loadImageHandler();
			$(window).on('resize', loadImageHandler);
			function loadImageHandlerWrap() {
				$('img[data-src]').each(function() {
					$(this).wrap('<span class="lazyload__wrap" />');
				});
			}
			loadImageHandlerWrap();
			$(document).on('lazybeforeunveil lazyloaded', function(e){
				$(e.target)
				.closest('.lazyload__wrap').attr('data-loaded', 'true');
			});
		}


		// ----------------------------------------
		// 電話番号リンク
		// ----------------------------------------
		function telLink() {
			if(navigator.userAgent.match(/(iPhone|Android)/)){
				$('.js-tel-link').each(function() {
					var str = $(this).html();
					if ($(this).children().is('img')) {
					$(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
						} else {
					$(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
					}
				});
			} else {
			}
		}


		// ----------------------------------------
		// テーブル
		// ----------------------------------------
		function table() {
			var $elem = $('.c-table-scroll');
			$elem.each(function () {
				$elem.append('<p class="js-scroll-icon"><img src="/assets/img/common/icon_scroll.svg" alt="左右にスワイプ可能です"></p>');
				$(this).scroll(function() {
					var init = $(this).scrollLeft();
					$(this).attr('data-scroll', 'true');
					if(init==0){
						$(this).attr('data-scroll', 'false');
					}
					$(this).addClass('is-scrolled');
				})
			});
		}
		
	});

}).call(this);



/* ------------------------------------------------------------
	その他のイベントを設定
 * ------------------------------------------------------------ */
// ----------------------------------------
// 背景に変換
// ----------------------------------------
function imgBackground() {
	$('.js-bg-img').each(function(i, el) {
		var src = $(el).data('src');
		$(el).css({'background-image': 'url('+src+')'});
	});
}


// ----------------------------------------
// スクロールバーの出現によるガタつきを防ぐ
// ----------------------------------------
function scrollBarhidden() {
	var body = document.body;
	var clientWidth = body.clientWidth;
	var noScrollBarWidth = clientWidth;
	var diff = 0;
	//スクロールバーを無くす前
	clientWidth = body.clientWidth;
	body.style.overflow = 'hidden';
	//スクロールバーを無くした後
	noScrollBarWidth = body.clientWidth;
	//スクロールバーの長さ計算
	diff = noScrollBarWidth - clientWidth;
	if (diff > 0) {
		$('body').css({
			'padding-right': diff
		});
		$('.c-header__menu-overlay').css({
			left: -diff
		});
	}
	//console.log(diff);
}


// ----------------------------------------
// モーダル（1回だけ呼び出す用）
// ----------------------------------------
function modal() {
	var $btn = $('.js-modal__link, .js-modal__img-link a');
	$btn.on('click', function() {
		var expanded = $(this).attr('aria-expanded');
		scrollBarhidden();
		if (expanded == 'true') {
			$('body').attr({
				style: ''
			});
			$('html, body').prop({
				scrollTop: sc
			});
			$('html').attr('data-modal', 'false');
		} else {
			sc = $(window).scrollTop();
			$('body').css({
				width: '100%',
				position: 'fixed',
				top: -sc,
				overflow: 'hidden'
			});
			$('html').attr('data-modal', 'true');
		}
	});
	$('.js-modal__link').on('click',function(){
		$('body').magnificPopup({
			delegate: '.js-modal__link',
			type: 'inline',
			mainClass: 'mfp-fade',
			closeMarkup: '<button class="mfp-close" type="button"></button>',
			callbacks: {
				open: function() {
					$('.mfp-wrap').css({
						position: 'fixed',
						top: 0
					});
					$('.js-modal').each(function() {
						var $this = $(this),
							$js_close = $this.find('.js-modal__close'),
							$mfp_close = $this.find('.mfp-close');
						if(($js_close.length)==true){
							$mfp_close.css({
								display: 'none'
							});
						}
					});
					$('.js-modal__close').on('click',function(e){
						e.preventDefault();
						$.magnificPopup.close();
					});
					$.fn.matchHeight._update(); // 高さ揃えの更新
				},
				close: function() {
					$('.mfp-wrap').attr({
						style: ''
					});
					$('body').attr({
						style: ''
					});
					$('html, body').prop({
						scrollTop: sc
					});
					$('html').attr('data-modal', 'false');
				},
			}
		});
	});
	$('.js-modal__img-link').each(function(){
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			mainClass: 'mfp-fade',
			image: {
				markup: '<div class="c-modal">'+
				'<div class="mfp-close"></div>'+
				'<div class="c-wrapper">'+
				'<div class="mfp-figure">'+
				'<div class="mfp-img"></div>'+
				'<div class="mfp-bottom-bar">'+
					'<div class="mfp-title"></div>'+
					'<div class="mfp-counter"></div>'+
				'</div>'+
				'</div>'+
				'</div>'+
				'</div>', // ポップアップのマークアップ。.mfp-imgはimgタグに、.mfp-closeはクローズボタンに置換される。
			},
			gallery: {
				enabled: true,
				tCounter:'%curr% / %total%',
				arrowMarkup: '<button class="c-modal__arrow c-modal__arrow-%dir% js-modal__arrow" type="button"><i class="c-icon-arrow-%dir% mfp-prevent-close" aria-hidden="true"></i></button>',
			},
			closeMarkup: '<button class="c-modal__close-button js-modal__close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>',
			callbacks: {
				// buildControls: function() {
				// 	// re-appends controls inside the main container
				// 	this.arrowLeft.appendTo(this.contentContainer);
				// 	this.arrowRight.appendTo(this.contentContainer);
				// },
				open: function() {
					$('.mfp-wrap').css({
						position: 'fixed',
						top: 0
					});
					$('.js-modal').each(function() {
						var $this = $(this),
							$js_close = $this.find('.js-modal__close'),
							$mfp_close = $this.find('.mfp-close');
						if(($js_close.length)==true){
							$mfp_close.css({
								display: 'none'
							});
						}
					});
					$('.js-modal__close').on('click',function(e){
						e.preventDefault();
						$.magnificPopup.close();
					});
				},
				close: function() {
					$('.mfp-wrap').attr({
						style: ''
					});
					$('body').attr({
						style: ''
					});
					$('html, body').prop({
						scrollTop: sc
					});
					$('html').attr('data-modal', 'false');
				},
			}
		});
	});
	//閉じる処理
	$('html[data-modal="true"] .mfp-close, .js-modal__close').on('click',function(e){
		e.preventDefault();
		$.magnificPopup.close();
	});
	//モーダル外のページ内リンクに飛びたい場合、その位置に移動
	$('.js-modal__outer-link').on('click',function(){
		var href = $(this).attr('href');
		var target = $(href == '#' || href == '' ? 'html' : href);
		if(common.viewWidth == 'pc'){
			//PCだったら
			$('html,body').animate({
				scrollTop: target.offset().top
			}, 0);
		} else {
			//スマホだったら
			$('html,body').animate({
				scrollTop: target.offset().top - $headerHeight
			}, 0);
		}
	});
	//モーダル内のアンカーリンクの処理
	$('.js-modal__inner-link').on('click',function(){
		var href = $(this).attr('href');
		var target = $(href == '#' || href == '' ? 'html' : href);
		var position = target.offset().top;
		var mado = $('.js-modal').offset().top;//これ大事
		$('.js-modal__inner').animate({
			scrollTop: position + $('.js-modal__inner').scrollTop() - mado
		}, 500);
	});
}
